import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/ru';
import 'dayjs/locale/az';
import { getLangDefinition } from './language';
import CONFIG from '../../config';
import { locationsQuery } from '../../queries';
import { setCityHasLocations, setError } from '../../actions';
import { TIME_FORMAT } from './constants';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatNumber = value => {
  if (typeof value === 'undefined') return value;
  const onlyNums = value.replace(/[^\d]/g, '').replace(/^0+/, '0');
  return onlyNums || undefined;
};

export const entitiesFilter = (array = [], searchString) => {
  return array.filter(
    item =>
      !item.name.toString().toLowerCase().indexOf(searchString.toLowerCase())
  );
};

export const getGoogleApiKey = () => {
  const isRunAtLocalhost =
    typeof window !== 'undefined'
      ? window.location.origin.match(/(lvh\.me|localhost)/g)
      : false;
  return isRunAtLocalhost ? CONFIG.googleApiKeyLocalhost : CONFIG.googleApiKey;
};

export const createRedirectUrlWithToken = (redirectUrl, accessToken) => {
  try {
    const url = new URL(redirectUrl);
    if (accessToken) {
      const { searchParams } = url;
      searchParams.set('access_token', accessToken);
      url.search = searchParams.toString();
    }

    return url.toString();
  } catch (e) {
    console.log('error while creating url', e);
  }
};

export const getObjCopy = obj => (obj ? JSON.parse(JSON.stringify(obj)) : {});

export const formatNumberWithComma = number => {
  if (typeof number === 'undefined') return number;
  return number.toLocaleString('ru', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const checkIsFlatCategory = categoryId => {
  const flatIds = ['1', '2', '3'];
  return flatIds.includes(categoryId);
};

export const toAreaFormat = value => (+value).toFixed(1);

export const getTitleWithFormattedArea = (title, { area, landArea }) => {
  const curr = area || landArea;
  return title.replace(
    `${curr.value} ${curr.units}`,
    `${toAreaFormat(curr.value)} ${curr.units}`
  );
};

export const isExistAndPositiveInteger = value => value && value > 0;

export const accusative = (word, lng) => {
  if (lng !== 'ru') {
    return word;
  }
  return word.replace(/а$/, 'у');
};

export const transformSlug = slug => {
  if (!slug) {
    return;
  }
  const slugArr = slug.split('/');
  slugArr.shift();
  return slugArr.join('/');
};

export const convertToNumber = value => {
  if ((typeof value === 'string' && !value.length) || value === undefined) {
    return;
  }
  return +value;
};

export const convertToBoolean = value => {
  if (typeof value !== 'boolean' || !value) {
    return;
  }
  return value;
};

export const getValueFromObject = (
  initialArray = [],
  property,
  returnProperty
) => {
  let result;
  let isExistInFirstLevel;

  if (!initialArray.length) {
    return;
  }

  for (let i = 0; i < initialArray.length; i += 1) {
    isExistInFirstLevel = Object.keys(initialArray[i])
      .map(k => initialArray[i][k])
      .includes(property);

    if (isExistInFirstLevel) {
      return initialArray[i][returnProperty];
    }

    if (initialArray[i].children) {
      result = initialArray[i].children.find(item =>
        Object.keys(item)
          .map(k => item[k])
          .includes(property)
      );

      if (result) {
        return result[returnProperty];
      }
    }
  }

  return result && result[returnProperty];
};

export const checkTodayOrYesterday = (today, date, i18n) =>
  date.isBefore(today) ? i18n.t('common.yesterday') : i18n.t('common.today');

export const getCardTime = (i, i18n) => {
  const now = new Date();
  const todayMidnight = dayjs(now.setHours(0, 0, 0, 0));
  const localeDate = dayjs(i).locale(getLangDefinition(i18n.language));

  if (dayjs(i).isBefore(todayMidnight.subtract(1, 'day'))) {
    return localeDate.format(TIME_FORMAT.fullDateOnly);
  }

  return `${checkTodayOrYesterday(
    todayMidnight,
    localeDate,
    i18n
  )} ${localeDate.format(TIME_FORMAT.timeOnly)}`;
};

export const formatDate = (d, i18n, template = TIME_FORMAT.fullDateTime) => {
  const localeDate = dayjs(d).locale(getLangDefinition(i18n.language));

  return localeDate.format(template);
};

export const formatToBakuTime = (isoDateStr, i18n, template) => {
  const bakuTime = dayjs(isoDateStr).utc().tz('Asia/Baku');
  return formatDate(bakuTime, i18n, template);
};

export const toQueryString = (obj, prefix) => {
  const str = [];
  let newPrefix;
  let property;

  Object.keys(obj).forEach(item => {
    newPrefix = prefix ? `${prefix}[]` : item.replace(/[[\]]/g, '');
    property = obj[item];

    str.push(
      typeof property === 'object'
        ? toQueryString(property, newPrefix)
        : `${encodeURI(newPrefix)}=${encodeURIComponent(property)}`
    );
  });

  return str.join('&');
};

export const isNotNil = value => value !== null && value !== undefined;

export const prettyNumber = number =>
  number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

export const formatToPrettyArea = value => prettyNumber(toAreaFormat(value));

export const setRangePrice = (priceMin, priceMax, currency) =>
  `${prettyNumber(priceMin)} \u2012 ${prettyNumber(priceMax)} ${currency}`;

export const getCallCenterPhones = () => {
  const {
    contacts: {
      tel,
      tel_url: telUrl,
      tel_additional: telAdditional,
      tel_additional_url: telAdditionalUrl
    }
  } = CONFIG;

  const phonesArr = [
    { tel, telUrl },
    { tel: telAdditional, telUrl: telAdditionalUrl }
  ];

  return phonesArr.filter(
    (phone, index) =>
      phonesArr.findIndex(item => item.tel === phone.tel) === index
  );
};

export const getFirstPaymentAmount = (price, percent) => {
  return Math.floor((price / 100) * percent);
};

export const setCoordinates = (data, change) => {
  const coordinates = {
    lat: data.latitude,
    lng: data.longitude
  };
  change(coordinates);
};

export const useCityHasLocations = cityId => {
  const dispatch = useDispatch();
  const locationsQueryVariables = {
    cityId,
    scope: 'ALL',
    limit: 1000
  };

  const { data, error, loading } = useQuery(
    locationsQuery(locationsQueryVariables),
    {
      variables: locationsQueryVariables
    }
  );

  useEffect(() => {
    if (error) {
      dispatch(setError(true));
    } else if (data) {
      const locations =
        data.locations?.filter(el => el.locationGroupId === '2') || [];
      const result = locations.length > 0;
      dispatch(setCityHasLocations(result));
    }
  }, [data, error, dispatch]);

  return { loading, hasLocations: data?.locations?.length > 0 };
};

export const isLeasedCategory = id => ['lease', 'sale'].includes(id);

export const snakeToCamelCase = str =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    );
export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const capitalizeFirstLetter = str =>
  str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : '';

export const getRequiringPhotoLength = (id, allCategories) => {
  let photosRequiredLength = null;

  // eslint-disable-next-line no-shadow
  const findCategory = (id, allCategories) => {
    if (!allCategories) return;

    allCategories.forEach(cat => {
      if (cat.id === id) {
        // eslint-disable-next-line prefer-destructuring
        photosRequiredLength = cat.photosRequired;
      } else if (cat.children && cat.children.length) {
        findCategory(id, cat.children);
      }
    });
  };

  findCategory(id, allCategories);

  return photosRequiredLength;
};
